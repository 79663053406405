import React, { useState } from 'react';
import TestimonialCard from '../Testimonial';
import ProfileImage from "../../assets/profile.svg"
import DavidImage from "../../assets/david.svg";
import AliyahImage from "../../assets/Aliyah.svg";
import EmilyImage from "../../assets/emily.svg";
import SarahImage from "../../assets/sarah.svg";
import LeftArrow from "../../assets/left-arrow.svg"
import RightArrow from "../../assets/right-arrow.svg"

const testimonialData = [
  {
    name: "Sarah",
    image: SarahImage,
    content: "MoreProperties helped me sell my house quickly and hassle-free. They offered a fair price, and the entire process was smooth. I highly recommend their house-buying services.",
  },
  {
    name: "Michael",
    image: DavidImage,
    content: "Selling my property was stress-free thanks to MoreProperties. They made a fair cash offer and closed the deal in no time. If you're looking to sell your house, they're the right choice.",
  },
  {
    name: "Emily",
    image: EmilyImage,
    content: "I needed to offload my property fast, and MoreProperties came to the rescue. Their straightforward buying process and fair cash offer made it a breeze to sell my house.",
  },
  {
    name: "Aliyah",
    image: AliyahImage,
    content: "MoreProperties made selling my house simple and efficient. They purchased my property regardless of its condition, and I received a fair cash offer. I couldn't be happier with the outcome.",
  },
];


export default function TestimonialSection() {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const testimonialsPerPage = 3;

  const handleScrollLeft = () => {
    if (visibleIndex > 0) {
      setVisibleIndex(visibleIndex - 1);
    }
  };

  const handleScrollRight = () => {
    if (visibleIndex + testimonialsPerPage < testimonialData.length) {
      setVisibleIndex(visibleIndex + 1);
    }
  };

  const visibleTestimonials = testimonialData.slice(
    visibleIndex,
    visibleIndex + testimonialsPerPage
  );

  return (
    <div className="flex flex-col items-center justify-center px-[20px] md:px-[40px] py-[100px]">
      <div className="w-full max-w-[1300px] flex flex-col overflow-x-hidden">
        <div className='py-[20px] md:mb-[40px]'>
          <h4 className='text-cabinet font-extrabold text-[32px] text-center md:text-[48px] leading-[34px] font-cabinet md:leading-[60px]'>What our <br/> customers say</h4>
        </div>
        <div className='flex flex-col md:flex-row md:space-x-[20px]'>
        {visibleTestimonials.map((testimony, index) => (
          <div key={index} className="w-full md:w-1/3">
            <TestimonialCard
              name={testimony.name}
              image={testimony.image}
              content={testimony.content}
            />
          </div>
        ))}
        </div>
        
      </div>

      <div className="flex justify-center space-x-[32px] mt-[32px]">
        <img
          src={LeftArrow}
          width={64}
          height={64}
          alt="scroll left"
          onClick={handleScrollLeft}
          style={{ cursor: 'pointer' }}
        />
        <img
          src={RightArrow}
          width={64}
          height={64}
          alt="scroll right"
          onClick={handleScrollRight}
          style={{ cursor: 'pointer' }}
        />
      </div>
    </div>
  );
}

