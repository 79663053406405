import React from 'react';
import StarIcon from '../../assets/star.svg'
import MaxWidthWrapper from '../MaxWidthWrapper';


const reasons = [
    'Life Changes',
    'Divorce',
    'Health issues',
    'Bankruptcy',
    'Divorce',
    'Relocation',
    'Retirement'
]

const conditions = [
    'Dilapidated house',
    'Natural disaster',
    'Fire outbreak',
    'Unfinished repairs',
    'Pests infection',
    'Unwanted items',
    'Retirement'
]

const challenges = [
    'Tax issues',
    'Legal issues',
    'No equity',
    'Personal issues',
    'Late payments',
    'Relocation',
    'Retirement'
]

export default function OfferSection() {
  return (
    <div className='py-[75px] md:py-[160px]'>
        <MaxWidthWrapper>
        <div className='flex flex-col items-center mb-[64px]'>
            <h4 className='text-[#03002F] text-[24px] md:text-[40px] leading-[30px] md:leading-[50px] font-extrabold text-center font-cabinet'>Offer Your Home <br/>Regardless of Its State</h4>
            <div className='bg-[#F8F3F3] px-[16px] py-[24px] max-w-[562px] rounded-[8px] mt-[24px]'>
            <h4 className='text-[14px] md:text-[16px] leading-[18px] md:leading-[26px] text-center font-bold font-satoshi'>“If you&apos;re seeking a quick, hassle-free cash sale for your house, you&apos;ve come to the right destination. No complications, no need for cleaning – we take care of everything on your behalf.”</h4>
        </div>
        </div>
        
        <div className='flex flex-col md:flex-row md:space-x-[23px] space-y-[24px] md:space-y-[0px]'>
            <div className='w-full md:w-1/3'>
                <div className='bg-[#9747FF] rounded-t-[8px] py-[23px] md:py-[25px]'>
                    <h4 className='text-white text-[20px] font-extrabold text-center font-cabinet'>Any Reason</h4>
                </div>
                <div className='bg-[#FAFAFA] space-y-[12px] md:space-y-[16px] pt-[20px] pb-[35px] rounded-b-[8px] px-[20px] md:px-[40px] border border-[#757272] border-t-0'>
                    {
                        reasons.map((item, index)=>{
                            return(
                                <div className='flex space-x-[7px] items-center' key={index}>
                                <img src={StarIcon} width={24} height={24} alt="star-icon" />
                                <p className='text-[14px] md:text-[16px] text-[#5A5A5A] font-bold font-satoshi'>{item}</p>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='w-full md:w-1/3'>
                <div className='bg-[#00BAC0] rounded-t-[8px] py-[23px] md:py-[25px]'>
                    <h4 className='text-white text-[20px] font-extrabold text-center font-cabinet'>Any Condition</h4>
                </div>
                <div className='bg-[#FAFAFA] space-y-[12px] md:space-y-[16px] pt-[20px] pb-[35px] rounded-b-[8px] px-[20px] md:px-[40px] border border-[#757272] border-t-0'>
                    {
                        conditions.map((item, index)=>{
                            return(
                                <div className='flex space-x-[7px] items-center' key={index}>
                                <img src={StarIcon} width={24} height={24} alt="star-icon" />
                                <p className='text-[14px] md:text-[16px] text-[#5A5A5A] font-bold font-satoshi'>{item}</p>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='w-full md:w-1/3'>
                <div className='bg-[#03002F] rounded-t-[8px] py-[23px] md:py-[25px]'>
                    <h4 className='text-white text-[20px] font-extrabold text-center font-cabinet'>Any Challenge</h4>
                </div>
                <div className='bg-[#FAFAFA] space-y-[12px] md:space-y-[16px] pt-[20px] pb-[35px] rounded-b-[8px] px-[20px] md:px-[40px] border border-[#757272] border-t-0'>
                    {
                        challenges.map((item, index)=>{
                            return(
                                <div className='flex space-x-[7px] items-center' key={index}>
                                <img src={StarIcon} width={24} height={24} alt="star-icon" />
                                <p className='text-[14px] md:text-[16px] text-[#5A5A5A] font-bold font-satoshi'>{item}</p>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
            
        </div>
        </MaxWidthWrapper>
    </div>
  )
}


