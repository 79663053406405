import React from 'react'

export default function LocationsSection() {
  return (
    <div className='bg-[#FAFAFA] py-[75px] flex justify-center px-[20px] md:px-[40px]'>
        <div className='w-full max-w-[1300px]'>
            <h4 className='text-[#9747FF] text-[32px] leading-[35px] md:text-[48px] md:leading-[60px] font-extrabold mb-[20px] font-cabinet'>Covered Locations</h4>
            <div className='w-full'><iframe width="100%" height="700" frameborder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=700&amp;hl=en&amp;q=Minnesota%20USA+()&amp;t=&amp;z=9&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/population/">Population Estimator map</a></iframe></div>
        </div>
        
    </div>
  )
}
