import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import BarnHouseImage from '../../assets/barnhouse.jpg';
import CorrectIcon from '../../assets/right.svg';
import WrongIcon from '../../assets/wrong.svg';

const reasons = [
  'Offer Within 24 Hours',
  'Sell Your House As-Is',
  'Find all the latest listings on the MLS',
  'Agent works with you from sales to close',
  'Sell in one go, with one team'
];

export default function WhyUsSection() {
  return (
    <div className='bg-[#010009] py-[50px] md:py-[185px] px-[20px] md:px-[40px] md:px-[75px] flex justify-center'>
      <div className='w-full max-w-[1300px]'>
        <div className='mb-[32px]'>
          <h4 className='text-[#FFA800] text-[32px] md:text-[40px] font-extrabold leading-[40px] md:leading-[50px] font-cabinet'>
            Why <br /> Moreproperties ?
          </h4>
        </div>
        <div className='flex flex-col md:flex-row md:space-x-[125px] space-y-[75px] md:space-y-[0px] items-center'>
          <div className='w-full md:w-1/2'>
            <div className='flex mb-[16px]'>
              <div className='w-1/2'>
                <h4 className='text-[14px] md:text-[20px] font-extrabold text-white font-cabinet'>Features</h4>
              </div>
              <div className='w-1/4 flex justify-end'>
                <h4 className='text-[14px] md:text-[20px] font-extrabold text-white font-cabinet'>Moreproperties</h4>
              </div>
              <div className='w-1/4 flex justify-end'>
                <h4 className='text-[14px] md:text-[20px] font-extrabold text-white font-cabinet'>Traditional</h4>
              </div>
            </div>
            <AnimatePresence initial={false}>
              {reasons.map((item, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.6, delay: index * 0.2 }}
                >
                  <div className='flex py-[17px] border-b-[1px] w-full'>
                    <div className='w-1/2'>
                      <h4 className='text-white text-[12px] md:text-[16px] font-bold font-satoshi'>{item}</h4>
                    </div>
                    <div className='w-1/4 flex justify-end'>
                      <img src={CorrectIcon} width={24} height={24} alt='Correct Icon' />
                    </div>
                    <div className='w-1/4 flex justify-end'>
                      <img src={WrongIcon} width={24} height={24} alt='Wrong Icon' />
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
          <div className='w-full md:w-1/2'>
            <img src={BarnHouseImage} width={522} height='auto' alt='Barn House' />
          </div>
        </div>
      </div>
    </div>
  );
}
