import React, {useState} from 'react';
import { Link } from 'react-router-dom'; 


const conditions =[
  'Needs total repair',
  'Fair condition',
  'Newly Renovated'
]


export default function GetOffer() {
  const [mechanicalCondition, setMechanicalCondition] = useState('')
  const [electricalCondition, setElectricalCondition] = useState('');

  const handleMechanicalConditionChange = (event) => {
    setMechanicalCondition(event.target.value);
  };

  const handleElectricalConditionChange = (event) => {
    setElectricalCondition(event.target.value);
  };
  return (
    <div className='bg-[#E9E7F4] min-h-screen flex justify-center px-[20px] md:px-[40px]'>
    <div className='flex space-x-[32px] py-[124px] max-w-[1300px]'>
        <div className='w-full'>
            <div className='bg-[#03002F] rounded-t-[32px] px-[30px] md:px-[100px] pt-[35px] pb-[53px]'>
            <h4 className='font-bold text-[24px] md:text-[40px] leading-[50px] text-[#F2F2F2] mb-[10px] font-cabinet'>
                Enter your details
            </h4>
            <p className='text-white text-[12px] md:text-[16px] font-satoshi font-bold'>
                You fill the form below with your personal information and attach pictures of the properties.
            </p>
            </div>
        <div className='bg-white rounded-b-[32px] px-[18px] py-[30px] md:p-[100px]'>
          <form className='md:space-y-[35px]'>
            {/* NAME AND EMAIL ROW */}
            <div className='md:flex md:space-x-[20px]'>
              <div className='w-full md:w-1/2'>
                <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                  <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>Name</label>
                  <input
                    type="text"
                    placeholder='Name'
                    className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                  />
                </div>
              </div>
              <div className='w-full md:w-1/2'>
                <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                  <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>Email</label>
                  <input
                    type="email"
                    placeholder='Email'
                    className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                  />
                </div>
              </div>
            </div>
            {/* ADDRESS AND PHONE ROW */}
            <div className='md:flex md:space-x-[20px]'>
              <div className='w-full md:w-1/2'>
                <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                  <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>Address</label>
                  <input
                    type="text"
                    placeholder='Address'
                    className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                  />
                </div>
              </div>
              <div className='w-full md:w-1/2'>
                <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                  <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>Phone</label>
                  <input
                    type="tel"
                    placeholder='Phone'
                    className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                  />
                </div>
              </div>
            </div>
            {/* LOT SIZE AND AGE OF ROOF ROW */}
            <div className='md:flex md:space-x-[20px]'>
              <div className='w-full md:w-1/2'>
                <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                  <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>Lot Size</label>
                  <input
                    type="text"
                    placeholder='Lot Size'
                    className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                  />
                </div>
              </div>
              <div className='w-full md:w-1/2'>
                <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                  <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>Age of Roof</label>
                  <input
                    type="text"
                    placeholder='Age of Roof'
                    className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                  />
                </div>
              </div>
            </div>
            {/* PRICE AND MEDIA ROW */}
              <div className='md:flex md:space-x-[20px]'>
                <div className='w-full md:w-1/2'>
                  <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                    <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>Price</label>
                    <input
                      type="text"
                      placeholder='Price'
                      className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                    />
                  </div>
                </div>
                <div className='w-full md:w-1/2'>
                  <div className='flex flex-col w-full mt-[31px]'>
                    <label htmlFor="fileInput" className='rounded-[8px] max-w-max bg-[#282727] text-[#F2F2F2] font-satoshi text-[14px] font-bold py-[19px] px-[34px]'>
    
                      Choose Pictures/Videos
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      multiple
                      accept="image/*, video/*"
                      className='hidden'
                    />
                  </div>
                </div>

              </div>
            {/* NUMBER OF BEDS, BATHS, AND SQFT ROW */}
            <div className='md:flex md:space-x-[20px]'>
              <div className='w-full md:w-1/3'>
                <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                  <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>Number of Beds</label>
                  <input
                    type="number"
                    placeholder='Number of Beds'
                    className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                  />
                </div>
              </div>
              <div className='w-full md:w-1/3'>
                <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                  <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>Number of Baths</label>
                  <input
                    type="number"
                    placeholder='Number of Baths'
                    className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                  />
                </div>
              </div>
              <div className='w-full md:w-1/3'>
                <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                  <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>Sqft</label>
                  <input
                    type="number"
                    placeholder='Sqft'
                    className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                  />
                </div>
              </div>
            </div>
            {/* MECHANICAL CONDITIONS AND ELECTRICAL CONDITIONS ROW */}
            <div className='md:flex md:space-x-[20px]'>
              <div className='w-full md:w-1/3'>
                  <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                    <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>
                      Mechanical Conditions
                    </label>
                    <select
                      value={mechanicalCondition}
                      onChange={handleMechanicalConditionChange}
                      className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                    >
                      <option value=''>Select Mechanical Condition</option>
                      {conditions.map((condition, index) => (
                        <option key={index} value={condition}>
                          {condition}
                        </option>
                      ))}
                    </select>
                </div>
              </div>
              <div className='w-full md:w-1/3'>
                  <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                    <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>
                      Electrical Conditions
                    </label>
                    <select
                      value={electricalCondition}
                      onChange={handleElectricalConditionChange}
                      className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                    >
                      <option value=''>Select Electrical Condition</option>
                      {conditions.map((condition, index) => (
                        <option key={index} value={condition}>
                          {condition}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              <div className='w-full md:w-1/3'>
                <div className='flex flex-col mb-[16px] md:mb-[0px]'>
                  <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px]'>Timeline</label>
                  <select
                    className='rounded-[8px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] h-[57px] bg-[#E3E3E3]'
                  >
                    {[...Array(8).keys()].map((week) => (
                      <option key={week} value={week + 1}>
                        {week + 1} {week === 0 ? 'week' : 'weeks'}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {/* PROPERTY DESCRIPTION ROW */}
            <div className='flex flex-col mb-[16px] md:mb-[0px]'>
              <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px] m'>Property Description</label>
              <textarea
                placeholder='Property description'
                className='rounded-[8px] min-h-[143px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] bg-[#E3E3E3]'
              />
            </div>
            {/* REPAIRS NEEDED ROW */}
            <div className='flex flex-col mb-[16px] md:mb-[0px]'>
              <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px] m'>Repairs Needed</label>
              <textarea
                placeholder='Repairs Needed'
                className='rounded-[8px] min-h-[143px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] bg-[#E3E3E3]'
              />
            </div>
            {/* MOTIVATION ROW */}
            <div className='flex flex-col mb-[16px] md:mb-[0px]'>
              <label className='font-bold text-[#282727] font-satoshi text-[14px] md:text-[15px] mb-[10px] m'>Motivation</label>
              <textarea
                placeholder='Motivation'
                className='rounded-[8px] min-h-[143px] border border-[#282727] text-[#282727] font-satoshi text-[12px] font-medium px-[10px] py-[15px] bg-[#E3E3E3]'
              />
            </div>
            <div className='bg-[#F8F3F3] p-[16px] rounded-[8px] my-[42px]'>
  <label className='flex items-center space-x-[10px]'>
    <input type="checkbox" className="form-checkbox text-[#CF7B00] text-[10px] md:text-[14px] leading-[14px] md:leading-[20px]" />
    <span className='text-[#CF7B00] text-[10px] md:text-[14px] leading-[14px] md:leading-[20px] font-satoshi font-bold'>
      Moreproperties takes your privacy seriously. We will never share your information with any 3rd party. 
      <span className='text-[#03002F] font-extrabold underline font-satoshi'>
        <Link to="/privacy-policy"> Click Here</Link>
      </span>{' '}
      to view our Privacy Policy. By clicking the button and submitting your information above, you agree and
      consent for Moreproperties to contact you via phone or sms regarding your inquiry on our site.
    </span>
  </label>
</div>

            <button className='bg-[#5F5CF0] w-[182px] rounded-[8px] py-[22px] text-white font-satoshi font-bold'>Get Offer</button>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
}
