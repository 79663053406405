import React from 'react';
import PhoneIcon from '../assets/phone.svg';
import EmailIcon from '../assets/email.svg';
import LocationIcon from '../assets/location.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';


const contactInfo = [
  {
    icon: PhoneIcon,
    title: 'Call Us',
    details: '+1 (612) 369-4616',
  },
  {
    icon: EmailIcon,
    title: 'Email Us',
    details: 'info@moreproperties.com',
  },
  {
    icon: LocationIcon,
    title: 'Find Us',
    details: '2355 Highway 36 West Bonestroo, Suite 400 , Roseville, MN, United States, Minnesota.',
  },
];


export default function Contact() {
    const {
        handleSubmit,
        control,
        formState: { errors },
      } = useForm();

      const onSubmit = async (data) => {
        console.log(data)
        try {
          const response = await axios.post('http://localhost:8000/contact', data);
          console.log(response)
          if (response.status === 200) {
            console.log("sent")
            // Handle success, reset the form, or perform other actions
            console.log('Form submitted successfully!');
            // Reset the form here if needed
          } else {
            console.error("e no work")
            // Handle errors, show an error message, or perform other actions
            console.error('Error submitting the form.');
          }
        } catch (error) {
          // Handle network errors or other exceptions
          console.error('Error:', error);
        }
      };


  return (
    <div className='bg-[#E9E7F4] min-h-screen flex justify-center px-[20px] md:px-[40px]'>
        <div className='flex flex-col md:flex-row space-y-[30px] md:space-y-0 md:space-x-[32px] py-[124px] w-full md:max-w-[1300px] justify-center'>
            <div className='w-full md:w-1/2'>
                <div className='bg-[#03002F] rounded-t-[32px] p-[30px]'>
                    <h4 className='font-bold text-[32px] leading-[34px] text-[#F2F2F2] font-cabinet'>Contact Us</h4>
                </div>
                <div className='bg-white rounded-b-[32px] p-[30px]'>
                    <form className='space-y-[35px]' onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col'>
                            <label className='font-bold font-satoshi text-[#757272] text-[15px] mb-[10px]'>Name</label>
                            <Controller
                            name="name"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Name is required' }}
                            render={({ field }) => (
                                <input
                                {...field}
                                type="text"
                                placeholder='Name'
                                className={`rounded-[8px] border border-[#757272] font-satoshi text-[#757272] text-[12px] font-medium px-[10px] py-[15px] ${
                                    errors.name ? 'border-red-500' : ''
                                }`}
                                />
                            )}
                            />
                            {errors.name && <p className='text-red-500 text-xs mt-1'>{errors.name.message}</p>}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-bold font-satoshi text-[#757272] text-[15px] mb-[10px]'>Phone</label>
                            <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Phone is required' }}
                            render={({ field }) => (
                                <input
                                {...field}
                                type="text"
                                placeholder='Phone'
                                className={`rounded-[8px] border border-[#757272] font-satoshi text-[#757272] text-[12px] font-medium px-[10px] py-[15px] ${
                                    errors.phone ? 'border-red-500' : ''
                                }`}
                                />
                            )}
                            />
                            {errors.name && <p className='text-red-500 text-xs mt-1'>{errors.name.message}</p>}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-bold font-satoshi text-[#757272] text-[15px] mb-[10px]'>Address</label>
                            <Controller
                            name="address"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Address is required' }}
                            render={({ field }) => (
                                <input
                                {...field}
                                type="text"
                                placeholder='Address'
                                className={`rounded-[8px] border border-[#757272] font-satoshi text-[#757272] text-[12px] font-medium px-[10px] py-[15px] ${
                                    errors.phone ? 'border-red-500' : ''
                                }`}
                                />
                            )}
                            />
                            {errors.name && <p className='text-red-500 text-xs mt-1'>{errors.address.message}</p>}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-bold font-satoshi text-[#757272] text-[15px] mb-[10px]'>Subject</label>
                            <Controller
                            name="subject"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Subject is required' }}
                            render={({ field }) => (
                                <input
                                {...field}
                                type="text"
                                placeholder='Subject'
                                className={`rounded-[8px] border border-[#757272] font-satoshi text-[#757272] text-[12px] font-medium px-[10px] py-[15px] ${
                                    errors.phone ? 'border-red-500' : ''
                                }`}
                                />
                            )}
                            />
                            {errors.name && <p className='text-red-500 text-xs mt-1'>{errors.subject.message}</p>}
                        </div>
                        <div className='flex flex-col'>
                            <label className='font-bold font-satoshi text-[#757272] text-[15px] mb-[10px]'>Description</label>
                            <Controller
                            name="description"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Description is required' }}
                            render={({ field }) => (
                                <textarea
                                {...field}
                                type="textarea"
                                placeholder='Description'
                                className={`rounded-[8px] min-h-[84px] border  border-[#757272] font-satoshi text-[#757272] text-[12px] font-medium px-[10px] py-[15px] ${
                                    errors.phone ? 'border-red-500' : ''
                                }`}
                                />
                            )}
                            />
                            {errors.name && <p className='text-red-500 text-xs mt-1'>{errors.description.message}</p>}
                        </div>
                        {/* <div className='flex flex-col'>
                            <label className='font-bold font-satoshi text-[#757272] text-[15px] mb-[10px] m'>Description</label>
                            <textarea 
                                placeholder='Description'
                                className='rounded-[8px] min-h-[84px] border  border-[#757272] font-satoshi text-[#757272] text-[12px] font-medium px-[10px] py-[15px]'
                            />
                        </div> */}
                        <button className='bg-[#5F5CF0] w-full rounded-[8px] py-[15px] text-white font-bold font-satoshi'>
                            Send Message
                        </button>
                        
                    </form>
                </div>

            </div>
            <div className='space-y-[20px] w-full md:w-1/2'>
      <AnimatePresence initial={false}>
        {contactInfo.map((info, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.6, delay: index * 0.2 }}
            className='bg-white px-[20px] md:px-[40px] py-[30px] flex items-center space-x-[15px] rounded-[12px] min-h-[135px]'
          >
            <div className='w-1/6 md:w-[64px]'>
              {/* Mobile Image */}
              <div className='hidden md:block'>
                <img src={info.icon} width={64} height={64} alt={`${info.title} icon`} />
              </div>

              {/* Desktop Image */}
              <div className='md:hidden'>
                <img src={info.icon} width={40} height={40} alt={`${info.title} icon`} />
              </div>
            </div>
            <div className='w-5/6'>
              <p className='text-[14px] md:text-[16px] font-bold leading-[20px] md:leading-[28px] text-[#757272] font-satoshi'>
                {info.title}
              </p>
              <h4 className='text-[#191919] text-[16px] md:text-[20px] leading-[28px] font-bold font-satoshi'>
                {info.details}
              </h4>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
        </div>
    </div>
  )
}
