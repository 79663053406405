import StarIcon from '../../assets/star.svg'
import AddIcon from '../../assets/plus.svg'
import MaxWidthWrapper from '../MaxWidthWrapper'
import { Link } from 'react-router-dom'
import ImageSlider from '../ImageSlider'

const HeroSection = () => {
    return(
        <div className="flex flex-col items-center py-[50px]">
            <div className='flex flex-col md:flex-row px-[20px] md:px-[40px] max-w-[1300px] md:space-x-[70px]'>
            <div className="w-full md:w-1/2 space-y-[24px] md:space-y-[32px] ">
                <div>
                <h1 className="text-[32px] md:text-[64px] font-extrabold text-[#1B1A1A] leading-[40px] md:leading-[75px] font-cabinet">Selling Your <span className="text-[#FFBD00]">House? </span>Discover the Easiest Way Here!</h1>
                </div>
                <div className="space-y-[12px] md:space-y-[16px]">
                    <div className="flex space-x-[10px]">
                        <img src={StarIcon} alt="star-icon" width={24} height={24} />
                        <p className="font-satoshi text-[#5A5A5A] text-[14px] md:text-[16px] font-bold ">Book pressure-free, private tours on your schedule</p>
                    </div>
                    <div className="flex space-x-[10px]">
                        <img src={StarIcon} alt="star-icon" width={24} height={24} />
                        <p className="font-satoshi text-[#5A5A5A] text-[14px] md:text-[16px] font-bold">Find all the latest listings on the MLS</p>
                    </div>
                    <div className="flex space-x-[10px]">
                        <img src={StarIcon} alt="star-icon" width={24} height={24} />
                        <p className="font-satoshi text-[#5A5A5A] text-[14px] md:text-[16px] font-bold">Expert help 7 days a week</p>
                    </div>
                </div>
                <div >
                    <Link to="get-offer">
                    <button className="text-white font-satoshi bg-[#03002F] py-[12px] md:py-[19px] px-[16px] md:px-[34px] rounded-[8px] flex items-center animate-bounce hover:animate-none">Sell Property <img src={AddIcon} width={24} height={24} className="ml-[8px]"/></button>
                    </Link>
                </div>
            </div>
            <div className="w-full md:w-1/2">
               <ImageSlider />
            </div>
            </div>
            
        </div>
    )
}



export default HeroSection