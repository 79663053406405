import React, { useState, useEffect } from 'react';
// import WhyHouse from '../assets/whyhouse.png';
// import BarnHouse from '../assets/barnhouse.png'

import HomeSlider1 from '../assets/homeslider1.png'
import HomeSlider2 from '../assets/homeslider2.png'
import HomeSlider3 from '../assets/homeslider3.png'
import HomeSlider4 from '../assets/homeslider4.png'
import HomeSlider5 from '../assets/homeslider5.png'
import HomeSlider6 from '../assets/homeslider6.png'
import HomeSlider7 from '../assets/homeslider7.png'
import HomeSlider8 from '../assets/homeslider8.png'
import HomeSlider9 from '../assets/homeslider9.png'









const images = [HomeSlider1, HomeSlider2, HomeSlider3, HomeSlider4, HomeSlider5, HomeSlider6, HomeSlider7, HomeSlider8, HomeSlider9]
const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      // Transition to the next image
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Adjust the time interval as needed (in milliseconds)

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="image-slider">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Image ${index}`}
          className={index === currentIndex ? 'active' : ''}
        />
      ))}
    </div>
  );
};

export default ImageSlider;
