import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

import AgentImage from '../../assets/agent.jpg';
import ClientRingsImage from '../../assets/clientrings.png';
import MaxWidthWrapper from '../MaxWidthWrapper';

export default function BestAgentSection() {
  const [isVisible, setIsVisible] = useState(false);
  const leftControls = useAnimation();
  const rightControls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('best-agent-section'); // Replace with the actual ID of your section
      if (section) {
        const sectionTop = section.offsetTop;
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;

        if (scrollY + windowHeight >= sectionTop) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      leftControls.start({ x: 0, opacity: 1, transition: { duration: 1 } });
      rightControls.start({ x: 0, opacity: 1, transition: { duration: 1 } });
    } else {
      leftControls.start({ x: '-100%', opacity: 0 });
      rightControls.start({ x: '100%', opacity: 0 });
    }
  }, [isVisible, leftControls, rightControls]);

  return (
    <div id="best-agent-section" className='py-[75px]'>
      <MaxWidthWrapper>
        <div className='flex flex-col md:flex-row md:space-x-[120px] space-y-[48px]'>
          <div className='w-full md:w-1/2'>
            <motion.img
              src={AgentImage}
              width={522}
              height={457}
              className="rounded-[4px]"
              initial={{ x: '-100%', opacity: 0 }}
              animate={leftControls}
            />
          </div>
          <div className='w-full md:w-1/2'>
            <motion.div
              initial={{ x: '100%', opacity: 0 }}
              animate={rightControls}
            >
              <h4 className='font-extrabold text-[32px] md:text-[48px] leading-[40px] md:leading-[60px] text-[#03002F] mb-[16px] md:mb-[24px] font-cabinet'>The right buyer <br /> is here</h4>
              <p className='text-[#757272] font-bold text-[14px] md:text-[16px] mb-[32px] font-satoshi'>Skip the inconvenience of immediately searching for an agent. When you&apos;re prepared to sell, we&apos;ll buy from you right away. We will assist you in formulating an offer, and providing guidance with your best interests as the top priority.</p>
              <div className='flex space-x-[32px] items-center'>
                <div>
                  <h1 className='text-[#00BAC0] text-[32px] md:text-[40px] font-extrabold font-cabinet'>+12k</h1>
                  <p className='text-[14px] text-[#1B1A1A] md:text-[16px] font-medium font-satoshi'>Houses bought</p>
                </div>
                <div>
                  <h1 className='text-[#9747FF] text-[32px] md:text-[40px] font-extrabold font-cabinet'>+1k</h1>
                  <p className='text-[14px] md:text-[16px] text-[#1B1A1A] font-medium font-satoshi'>Clients Served</p>
                </div>
                <div>
                  <img src={ClientRingsImage} width={148} height={40} alt="serviced clients" />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
}
