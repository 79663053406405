import React from 'react'

import { FAQSection,HeroSection,BestAgentSection,HowToSellSection,NewsletterSection, OfferSection, TestimonialSection, WhySellToUsSection, WhyUsSection, LocationsSection} from "../components/home";


export default function HomePage() {
    return (
        <div>
          <HeroSection />
          <HowToSellSection />
          <BestAgentSection />
          <WhySellToUsSection />
          <WhyUsSection />
          <OfferSection />
          <NewsletterSection />
          <TestimonialSection />
          <FAQSection />
          <LocationsSection />
        </div>
      )
}
