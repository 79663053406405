import React from 'react'
import { Link } from 'react-router-dom';
import AddIcon from '../assets/plus.svg'
import StarIcon from '../assets/purplestar.svg'




const propertyIssues = [
    "Are You In Foreclosure or Are About To Be?",
    "Do You Own Unwanted Rental Property?",
    "Do You Have Frustrating Tenants (Or Family Members) That You Can’t Get Rid Of?",
    "Do You Own A Vacant Property?",
    "Did You Inherit An Unwanted Property?",
    "Do You Want To Avoid Paying Realtor Commissions?",
    "Do You Need To Relocate Quickly And Need To Sell Your Current House Fast?",
    "Do You Have Little Or No Equity And Need To Sell?",
    "Are You Going Through A Divorce?"
  ];

export default function AboutPage() {
  return (
    <div className='flex justify-center px-[20px] md:px-[40px] py-[75px]'>

    <div className="max-w-[1300px]">
        <div className='flex flex-col space-y-[24px]'>
            <h4 className='text-[#1B1A1A] font-bold text-[32px] md:text-[64px] leading-[40px] md:leading-[80px] font-cabinet'>About Us</h4>
            <div className='text-[14px] md:text-[16px] leading-[24px] flex flex-col space-y-[8px] font-satoshi'>
                <p>
                Morepropertiesrei.com is a real estate company that is here to make you feel fulfilled and assured about the decision you made in selling off your house.We are here to make sure you get a benefit-laden experience while you are at it, having a worry free transaction at no cost to you.</p>
                <p>We cover all your costs (repairs, cleaning, closure costs… almost all costs!)Too old and tired to keep up with your house maintenance and functionality?</p> Let us help you take over that stress, so that you can have enough free time to do all the relaxing and fun exercises you dream of. <p>
                Our goal is to give you ease and peace all through the process of selling off your home…
                </p>
            </div>
            <div className='mb-[40px]'>
                <Link to="/get-offer">
                    <button className="text-white text-[14px] leading-[20px] font-bold bg-[#03002F] py-[12px] md:py-[19px] px-[16px] md:px-[34px] rounded-[8px] flex items-center font-satoshi">Sell Your Property <img src={AddIcon} width={24} height={24} alt="add icon" className="ml-[8px]"/></button>
                </Link>
            </div>
        </div>
        <div className='flex flex-col md:flex-row items-center md:space-x-[125px] py-[50px]'>
            <div className='w-full md:w-1/2'>
                <h4 className='text-[#1B1A1A] font-bold text-[24px] leading-[35px] md:text-[48px] md:leading-[54px] font-cabinet'>Find Out If Your Property Qualifies For Us To Make You An All Cash Offer Below</h4>
                <button className='text-white text-[14px] font-bold leading-[20px] mt-[32px] bg-[#5F5CF0] w-[184px] rounded-[8px] px-[19px] py-[20px]'>
                    Contact us
                </button>
            </div>
            <div className='w-full md:w-1/2 space-y-[25px]'>
                {
                    propertyIssues.map((issue, index)=>{
                        return(
                            <div className='flex space-x-[8px]' key={index}>
                                <img src={StarIcon} width={24} height={24} alt="issue icon"/>
                                <p className='text-[#5A5A5A] text-[14px] md:text-[16px] leading-[22px] font-bold font-satoshi'>{issue}</p>
                            </div>
                        )
                    })
                }
            </div>

        </div>

    </div>
    </div>

  )
}
