import React from 'react';

export default function Privacy() {
  return (
    <div className='bg-[#EFEFEF] px-[20px] md:px-[40px] py-[50px] md:p-[100px] flex justify-center'>
        <div className='bg-[#F9F9F9] px-[30px] py-[50px] md:p-[100px] rounded-[32px] space-y-[50px] max-w-[1300px]'>
            <h4 className='font-bold text-[40px] leading-[50px] mb-[40px] font-cabinet'> Privacy Policy</h4>
            <div>
                <h4 className='text-[20px] font-bold mb-[15px] font-cabinet'>1. TERMS</h4>
                <p className='text-[16px] text-[#5A5A5A] font-satoshi font-bold'>By visiting this website, you are consenting to adhere to the website&apos;s Terms and Conditions of Use, in accordance with applicable laws and regulations. If you do not agree with any of these terms and conditions, you are not permitted to use or access this site. The materials featured on this site are protected by applicable copyright and trademark laws</p>
            </div>
            <div>
                <h4 className='text-[20px] font-bold mb-[15px] font-cabinet'>2. LICENSE</h4>
                <p className='text-[16px] text-[#5A5A5A] font-satoshi font-bold '>Permission is allowed to temporarily download one duplicate of the materials (data or programming) on Company&apos;s site for individual and non-business use only. This is just a permit of license and not an exchange of title, and under this permit, you may not:</p>
                <ul className='my-[15px] text-[16px] font-satoshi'>
                    <li>modify or copy the materials;</li>
                    <li>use the materials for any commercial use, or for any public presentation (business or non-business);</li>
                    <li>attempt to decompile or rebuild any product or material contained on Company&apos;s site;</li>
                    <li>remove any copyright or other restrictive documentations from the materials;</li>
                    <li>or transfer the materials to someone else or even &quot;mirror&quot; the materials on other servers.</li>
                </ul>
                <p className='text-[16px] text-[#5A5A5A] font-satoshi font-bold'>This permit might consequently be terminated if you disregard any of these confinements and may be ended by Company whenever deemed. After permit termination or when your viewing permit is terminated, you must destroy any downloaded materials in your ownership whether in electronic or printed form.</p>
            </div>
            <div>
                <h4 className='text-[20px] font-bold mb-[15px] font-cabinet'>3. DISCLAIMER</h4>
                <p className='text-[16px] text-[#5A5A5A] font-satoshi font-bold'>The materials provided on the Company&apos;s website are presented &apos;as is.&apos; The Company does not provide any warranties, either expressed or implied, and hereby disclaims and negates all other warranties, including but not limited to implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Additionally, the Company does not warrant or make any representations regarding the accuracy, likely outcomes, or reliability of the use of these materials on its website or otherwise related to such materials or any sites linked to this website</p>
            </div>
            <div>
                <h4 className='text-[20px] font-bold mb-[15px] font-cabinet'>4. CONSTRAIN</h4>
                <p className='text-[16px] text-[#5A5A5A] font-satoshi font-bold'>Under no circumstances shall the Company or its suppliers be liable for any damages, including, without limitation, damages for loss of data or profit, or due to business disruption, arising out of the use or inability to use the materials on the Company&apos;s website, even if a Company authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties or limitations of liability for consequential or incidental damages, these limitations may not apply to you</p>
            </div>
            <div>
                <h4 className='text-[20px] font-bold mb-[15px] font-cabinet'>5. AMENDMENTS</h4>
                <p className='text-[16px] text-[#5A5A5A] font-satoshi font-bold'>At no time shall the Company or its suppliers be liable for any damages, including but not limited to damages for loss of data or profit, or due to business disruption, resulting from the use or inability to use the materials on the Company&apos;s website, even if a Company authorized representative has been informed, whether orally or in writing, of the possibility of such damage. Some jurisdictions may not allow limitations on implied warranties or the exclusion or limitation of liability for consequential or incidental damages, so these restrictions may not apply to you</p>
            </div>
            <div>
                <h4 className='text-[20px] font-bold mb-[15px] font-cabinet'>6. PRIVACY POLICY</h4>
                <p className='text-[16px] text-[#5A5A5A] font-satoshi font-bold'>Your privacy is of utmost importance to us. We have formulated this policy to ensure that you understand how we collect, use, share, and disclose personal information. The following outlines our privacy policy:</p>
                <ul className='my-[15px] text-[16px] font-satoshi '>
                    <li>Prior to or at the time of gathering personal information, we will clearly state the purposes for which the information is being collected.</li>
                    <li>We will collect and use personal information solely for the purposes stated by us and for other legitimate reasons, unless we obtain the consent of the individual concerned or as required by law.</li>
                    <li>Personal information will only be retained for as long as necessary to fulfill the specified purposes.</li>
                    <li>We will gather personal information through legal and fair means and, where appropriate, with the knowledge or consent of the individual concerned.</li>
                    <li>Personal information should be relevant to the purposes for which it will be used and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.</li>
                    <li>We will safeguard personal information through security measures to protect against loss or theft, as well as unauthorized access, disclosure, copying, use, or alteration.</li>
                    <li>We will promptly provide individuals with access to our policies and procedures regarding the management of personal information.</li>
                    <li>We are committed to conducting our business in accordance with these principles to ensure the security and preservation of the privacy of personal information.</li>
                </ul>
                <p className='text-[16px] text-[#5A5A5A] font-satoshi font-bold'>This permit might consequently be terminated if you disregard any of these confinements and may be ended by Company whenever deemed. After permit termination or when your viewing permit is terminated, you must destroy any downloaded materials in your ownership whether in electronic or printed form.</p>
            </div>
        </div>
    </div>
  )
}
