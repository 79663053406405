import React from 'react';
import WhyImage from '../../assets/whyhouse.jpg';


const reasons = [
    {
        title: "No Repairs Needed",
        description: "Tell us everything about the property, the type of house it is, and the condition the house is in at the moment."
    },
    {
        title: "No Commission",
        description: "You won't have to pay a real estate agent's commission, which means you keep more of the sale price."
    },
    {
        title: "Pick your closing date",
        description: " You have flexibility to choose the date that works best for you, making the process convenient."
    },
    {
        title: "No Fees",
        description: "There are no hidden fees or costs, providing transparency and peace of mind."
    }
]

export default function WhySellToUsSection() {
  return (
    <div className='py-[75px] flex justify-center px-[20px] md:px-[40px]'>
    <div className='flex flex-col md:flex-row md:space-x-[125px] justify-between w-full max-w-[1300px]'>
        <div className='w-full md:w-1/2'>
            <div className='mb-[24px]'>
                <h4 className='text-[#FF8E00] font-extrabold text-[32px] md:text-[48px] leading-[40px] md:leading-[60px] font-cabinet'>
                    Why you should <br/>sell to us
                </h4>
            </div>
            {
                reasons.map((item, index)=>{
                    return(
                        <div className='px-[16px] mb-[28px]  border-[#000] border-l-[1px]' key={index}>
                            <h4 className='text-[20px] font-extrabold text-[#03002F] leading-[25px] mb-[5px] font-cabinet'>{item.title}</h4>
                            <p className='text-[#757272] text-[14px] md:text-[16px] font-bold font-satoshi'>{item.description}</p>
                        </div>
                    )
                })
            }
        </div>
        <div className='w-full md:w-1/2'>
            <img src={WhyImage} alt="haunted house" width={558} height="auto" className="rounded-[4px]"/>
        </div>
    </div>

    </div>

  )
}
