import React, { useState } from 'react';
import LogoImage from '../assets/logo.png';
import CloseMenuIcon from '../assets/close-menu.svg';
import HamburgerIcon from '../assets/hamburger.svg';
import { Link, NavLink } from 'react-router-dom'; // Assuming you are using React Router
import { useEffect } from 'react';
import { motion, usePresence, useAnimate, createScopedAnimate } from 'framer-motion';
import '../index.css'



const navlinks = [
    {
        title: "Home",
        link: "/"
    },
    {
        title: "About",
        link: "/about-us"
    },
    {
        title: "FAQ",
        link: "/#faq"
    },
    {
        title: "Contact Us",
        link: "/contact-us"
    }
]


const MobileNav = ({ isOpen, onClose }) => {
    const closeMobileMenu = () => {
        onClose();
    };

    // Animation variants
    const itemVariants = {
        hidden: { opacity: 0, x: -20 },
        visible: { opacity: 1, x: 0 },
    };

    return (
        <motion.div
            initial="hidden"
            animate={isOpen ? "visible" : "hidden"}
            variants={{
                hidden: { opacity: 0 },
                visible: { opacity: 1 },
            }}
            transition={{ duration: 0.3 }}
            className={`bg-[#03002F] py-[50px] px-[20px] md:px-[40px] flex flex-col w-full min-h-screen ${isOpen ? 'block' : 'hidden'}`}
        >
            <div className='flex justify-end'>
                <button onClick={closeMobileMenu}>
                    <img src={CloseMenuIcon} width={30} height={30} alt="close menu" />
                </button>
            </div>
            <motion.div
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 },
                }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className='flex flex-col space-y-[16px]'>
                <motion.div
                    initial="hidden"
                    animate={isOpen ? "visible" : "hidden"}
                    variants={{
                        hidden: { opacity: 0, x: -20 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    transition={{ duration: 0.3, delay: 0.3 }}
                >
                    <Link to={navlinks[0].link} onClick={closeMobileMenu}>
                        <p className='font-satoshi text-[16px] font-bold leading-[20px] py-[16px] text-white'>{navlinks[0].title}</p>
                    </Link>
                </motion.div>
                <motion.div
                    initial="hidden"
                    animate={isOpen ? "visible" : "hidden"}
                    variants={{
                        hidden: { opacity: 0, x: -20 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    transition={{ duration: 0.3, delay: 0.6 }}
                >
                    <Link to={navlinks[1].link} onClick={closeMobileMenu}>
                        <p className='text-[16px] font-bold leading-[20px] py-[16px] text-white'>{navlinks[1].title}</p>
                    </Link>
                </motion.div>
                <motion.div
                    initial="hidden"
                    animate={isOpen ? "visible" : "hidden"}
                    variants={{
                        hidden: { opacity: 0, x: -20 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    transition={{ duration: 0.3, delay: 0.9 }}
                >
                    <Link to={navlinks[2].link} onClick={closeMobileMenu}>
                        <p className='text-[16px] font-bold leading-[20px] py-[16px] text-white'>{navlinks[2].title}</p>
                    </Link>
                </motion.div>
                <motion.div
                    initial="hidden"
                    animate={isOpen ? "visible" : "hidden"}
                    variants={{
                        hidden: { opacity: 0, x: -20 },
                        visible: { opacity: 1, x: 0 },
                    }}
                    transition={{ duration: 0.3, delay: 1.2 }}
                >
                    <Link to={navlinks[3].link} onClick={closeMobileMenu}>
                        <p className='text-[16px] font-bold leading-[20px] py-[16px] text-white'>{navlinks[3].title}</p>
                    </Link>
                </motion.div>
            </motion.div>
            <div className='mt-[35px]'>
                <Link to="/get-offer">
                <button className='bg-[#fff] font-satoshi rounded-[8px] text-[#03002F] text-[14px] font-medium px-[30px] py-[15px]' onClick={closeMobileMenu}>
                    Get offer
                </button>
                </Link>
            </div>
        </motion.div>
    )
}





const DesktopNav = () => {

    return (
        <div className='bg-[#FAFAFA] py-[20px] px-[40px] flex justify-center h-[90px]'>
            <div className='w-full max-w-[1300px] flex justify-between items-center'>
                <div>
                    <Link to="/">
                    <img src={LogoImage} height={51} width={85} alt="moreproperties logo" />
                    </Link>
                </div>
                <div className='flex space-x-[28px]'>
                    {navlinks.map((link) => (
                        <NavLink
                            to={link.link}
                            key={link.title}
                            className='font-satoshi text-[16px] font-bold leading-[20px] px-[16px] text-[#1B1A1A]'
                            activeclassname='active-link border-b-2 border-blue-500' // Tailwind classes for active link styling
                        

                        >
                            <p className='font-satoshi text-[16px] font-bold leading-[20px] px-[16px] text-[#1B1A1A]'>{link.title}</p>
                        </NavLink>
                    ))}
                </div>
                <div>
                    <input
                        type="text"
                        className='rounded-[12px]'
                        placeholder='Pick your location, city, state'
                    />
                </div>
                <div>
                    <NavLink to="/get-offer">
                        <button className='font-satoshi rounded-[8px] bg-[#03002F] text-white text-[14px] font-medium px-[30px] py-[15px] animate-pulse hover:animate-none'>
                            Get Offer
                        </button>
                    </NavLink>
                </div>
            </div>
        </div>
    );
}



export default function Navbar() {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    const toggleMobileNav = () => {
        console.log("clicked")
        setMobileNavOpen(!isMobileNavOpen);
    }

    const closeMobileNav = () => {
        setMobileNavOpen(false);
    }

    return (
        <div>
            {
                !isMobileNavOpen ? (
                    <div className='block md:hidden px-[20px] md:px-[40px] py-[17px]'>
                        <div className='flex justify-between'>
                            <Link to="/">
                            <img src={LogoImage} width={85} height={51} alt="logo" />
                            </Link>
                            <button onClick={toggleMobileNav}>
                                <img src={HamburgerIcon} width={30} height={30} alt="menu" onClick={toggleMobileNav}/>
                            </button>
                        </div>
                    </div>
                ) : null
            }
            
            <div className='hidden md:block'>
                <DesktopNav />
            </div>
            <MobileNav isOpen={isMobileNavOpen} onClose={closeMobileNav} />
        </div>
    )
}

