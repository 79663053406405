import React, {useState} from 'react';
import FAQIcon from '../../assets/faq.svg';
import ArrowDownIcon from '../../assets/arrow-down.svg';
import FAQEmailIcon from '../../assets/FAQmail.svg';
import FAQPhoneIcon from '../../assets/FAQcall.svg';
import { Link } from 'react-router-dom'; // Assuming you are using React Router



const faq = [
    {
        question: "Do you pay fair prices for properties?",
        answer: "Yes, we are committed to offering fair and competitive prices for properties. We conduct thorough assessments and market analysis to ensure that our offers reflect the true value of your property."
    },
    {
        question: "How do you determine the price to offer on my house?",
        answer: "Our pricing is based on a comprehensive evaluation of various factors, including your property's location, condition, current market trends, and comparable property sales. We aim to provide you with a competitive offer that aligns with market conditions."
    },
    {
        question: "Are there any fees or commissions to work with you?",
        answer: "No, there are no hidden fees or commissions when you choose to work with us. Our goal is to simplify the selling process for you, and we cover all the associated costs, making it a hassle-free experience."
    },
    {
        question: "Is there any obligation when I submit my info?",
        answer: "No, there is absolutely no obligation when you submit your property information to us. You are free to explore our offer without any commitment. We believe in providing you with information and options so you can make an informed decision."
    },
    {
        question: "Which locations are your agents available?",
        answer: "Currently, our agents are available throughout Minnesota, USA. We are proud to serve this region extensively. Additionally, we are actively working on expanding our services to other cities and regions in the near future. Please feel free to inquire about our coverage for your specific location."
    }
];



const ContactCard = () => {
    return(
        <div className='rounded-[8px] w-full md:max-w-[331px] bg-[#03002F] px-[30px] py-[24px] flex flex-col space-y-[16px]'>
                    <div className='flex space-x-[12px]'>
                        <img src={FAQPhoneIcon} width={24} height={24} alt="call-icon"/>
                        <p className='text-[16px] font-bold text-[#FFA800] font-satoshi'>+1 (612) 369-4616</p>
                    </div>
                    <div className='flex space-x-[12px]'>
                        <img src={FAQEmailIcon} width={24} height={24} alt="call-icon"/>
                        <p className='text-[16px] font-bold text-[#FFA800] font-satoshi'>info@moreproperties.com</p>
                    </div>

                </div>
    )
}

export default function FAQSection() {
    const [openIndex, setOpenIndex] = useState(null);
  
    const toggleAnswer = (index) => {
      if (openIndex === index) {
        // If the clicked question is already open, close it
        setOpenIndex(null);
      } else {
        // If a different question is clicked, open it
        setOpenIndex(index);
      }
    };
  
    return (
      <div id="faq" className='flex justify-center px-[20px] md:px-[40px] py-[75px]'>
        <div className='flex flex-col md:flex-row md:space-x-[125px] w-full max-w-[1300px]'>
          <div className='w-full md:w-1/2 flex flex-col space-y-[40px]'>
            <div className='flex justify-between'>
              <h4 className='text-[#1B1A1A] text-[24px] md:text-[40px] font-extrabold'>Frequently Asked <br /> Questions</h4>
              <img src={FAQIcon} alt="faq icon" width={80} height={80} />
            </div>
            <div className='hidden md:block'>
              <ContactCard />
            </div>
          </div>
          <div className='w-full md:w-1/2'>
            {faq.map((item, index) => (
              <div className='border-b border-[#757272] py-[24px]' key={index}>
                <div
                  className='flex justify-between items-center cursor-pointer'
                  onClick={() => toggleAnswer(index)}
                >
                    <div className='flex space-x-[16px] items-center'>
                        <h4 className='text-[25px] md:text-[32px] font-bold font-cabinet'>{index + 1}</h4>
                        <p className='text-[16px] md:text-[16px] font-bold font-satoshi'>{item.question}</p>
                    </div>
                  
                    <img src={ArrowDownIcon} width={24} height={24} alt="open faq" className={openIndex === index ? 'rotate-180' : ''} />
                </div>
                {openIndex === index && (
                  <p className='text-[14px] mx-[33px] mt-[10px] font-satoshi'>{item.answer}</p>
                )}
              </div>
            ))}
          </div>
          <div className='block md:hidden mt-[80px]'>
            <ContactCard />
          </div>
        </div>
      </div>
    );
  }
