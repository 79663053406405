import React from 'react';
// import ProfileImage from '../assets/profile.svg';
import StarsIcon from '../assets/stars.svg';

export default function TestimonialCard({name, image, content}) {
  return (
    <div className='bg-[#FBFBFB] rounded-[16px] max-w-[405px] p-[20px]'>
        <div className='flex items-center'>
            <img src ={image} height={56} width={56} className="mr-[10px]"/>
            <p className='font-bold text-[18px]'>{name}, <span className='text-[#FFA800] ml-[8px]'>Minnesota</span></p>
        </div>
        <div className='mt-[20px]'>
            <p className='text-[15px] font-medium text-[#403E3E] leading-[24px]'>{content}</p>
        </div>
        <div className='mt-[20px]'>
            <img src={StarsIcon} width="auto" height="auto" />
        </div>
    </div>
  )
}
