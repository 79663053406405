import React from 'react'

export default function NewsletterSection() {
  return (
    <div className='flex w-full justify-center px-[20px] md:px-[40px] py-[36px] md:py-[50px]'>
        <div className='w-full border border-[#5F5CF0]  max-w-[800px] rounded-[24px] md:rounded-[48px] px-[18px] py-[36px] md:py-[80px] md:px-[55px]'>
        <div className='flex flex-col md:items-center'>
            <h4 className='text-[#5F5CF0] text-[24px] leading-[34px] md:text-[48px] font-extrabold md:leading-[52px] mb-[25px] text-left md:text-center font-cabinet'>Receive expert advice<br/> directly to your email</h4>
            <p className='text-[16px] leading-[20px] text-[#757272] md:text-center max-w-[500px] font-satoshi font-bold'>By subscribing to our newsletter, you get updates from <span className='text-[#5F5CF0] font-bold'>Moreproperties </span> and you are accepting our Privacy Policy</p>
        </div>
        <div className='mt-[40px] flex justify-center'>
            <form className='w-full max-w-[485px]'>
                <div className='flex flex-col space-y-[16px] md:space-y-[0px] md:flex-row md:space-x-[15px]'>
                    <input type="text" 
                        placeholder='Enter your email'
                        className='border border-[#757272] rounded-[12px] px-[16px] py-[14.5px] w-full text-[14px] leading-[0px] max-h-[48px]'
                    />
                    <button className='rounded-[8px]  bg-[#5F5CF0] text-white text-[14px] font-medium px-[33px] py-[15px] max-h-[48px]'>Subscribe</button>
                </div>
                
            </form>
        </div>
    </div>
    </div>
    
  )
}
