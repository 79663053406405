import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
import FacebookIcon from '../assets/fb.svg';

const sellHomeLinks = [
    {
        title: "Get an Offer",
        link: "/get-offer"
    },
    {
        title: "Reviews",
        link: "/#reviews"
    },
    {
        title: "FAQ",
        link: "#faq"
    },
];

const companyLinks = [
    {
        title: "About Us",
        link: "/about-us"
    },
    {
        title: "Privacy Policy",
        link: "/privacy-policy"
    },
    {
        title: "Contact Us",
        link: "/contact-us"
    },
];

export default function Footer() {
    return (
        <div>
            <footer className='bg-[#191919] md:h-[454px] flex justify-center px-[20px] md:px-[40px]'>
                <div className='flex flex-col space-y-[35px] md:space-y-[0px] md:flex-row justify-between py-[65px] md:py-[100px] w-full max-w-[1300px]'>
                    <div className='flex flex-col space-y-[32px]'>
                        <div>
                            <h4 className='text-[#FFA800] text-[16px] leading-[28px] font-[800]'>Call Us</h4>
                            <p className='text-[20px] leading-[28px] font-satoshi font-bold text-[#BEBEBE]'>+1 (612) 369-4616</p>
                            <p className='text-[20px] leading-[28px] font-satoshi font-bold text-[#BEBEBE]'>+1 612-324-9922</p>
                        </div>
                        <div>
                            <h4 className='text-[#FFA800] text-[16px] leading-[28px] font-[800]'>Email Us</h4>
                            <p className='text-[20px] leading-[28px] font-satoshi font-bold text-[#BEBEBE]'>info@morepropertiesrei.com</p>
                        </div>

                    </div>
                   
                    <div>
                        <h4 className='text-white text-[16px] leading-[28px] font-[800] mb-[16px] md:mb-[30px]'>Sell your home</h4>
                        {
                            sellHomeLinks.map((item, index) => (
                                <Link to={item.link} key={index}>
                                    <p className='text-[#BEBEBE] text-[16px] font-medium leading-[40px]' key={index}>{item.title}</p>
                                </Link>
                            ))
                        }
                    </div>
                    <div>
                        <h4 className='text-white text-[16px] leading-[28px] font-[800] mb-[16px] md:mb-[30px]'>Company</h4>
                        {
                            companyLinks.map((item, index) => (
                                <Link to={item.link} key={index}>
                                    <p className='text-[#BEBEBE] text-[16px] font-medium leading-[40px]' key={index}>{item.title}</p>
                                </Link>
                            ))
                        }
                    </div>
                    <div>
                        <h4 className='text-white text-[16px] leading-[28px] font-[800] mb-[16px] md:mb-[30px]'>Socials</h4>
                        <a href="https://www.facebook.com">
                            <img src={FacebookIcon} alt="facebook-logo" width={50} height={50} />
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
}
