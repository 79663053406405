import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

import HomePhone from '../../assets/sell-phone.svg';
import GetOfferIcon from '../../assets/getoffer.svg';
import GetPaidIcon from '../../assets/getpaid.svg';
import MaxWidthWrapper from '../MaxWidthWrapper';

export default function HowToSellSection() {
  const [isVisible, setIsVisible] = useState(false);
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('how-to-sell'); // Replace with the actual ID of your section
      if (section) {
        const sectionTop = section.offsetTop;
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;

        if (scrollY + windowHeight >= sectionTop) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      controls1.start({ x: 0, opacity: 1, transition: { duration: 0.5 } });
      controls2.start({ x: 0, opacity: 1, transition: { duration: 0.5, delay: 0.2 } });
      controls3.start({ x: 0, opacity: 1, transition: { duration: 0.5, delay: 0.4 } });
    } else {
      controls1.start({ x: '-100%', opacity: 0 });
      controls2.start({ x: '-100%', opacity: 0 });
      controls3.start({ x: '-100%', opacity: 0 });
    }
  }, [isVisible, controls1, controls2, controls3]);

  return (
    <div id="how-to-sell" className='py-[75px]'>
      <MaxWidthWrapper>
        <div className='bg-[#03002F] px-[17px] md:px-[68px] py-[40px] md:py-[70px] rounded-[24px] md:rounded-[32px]'>
          <div className='flex justify-center mb-[30px] md:mb-[40px]'>
            <h4 className='text-[#CACACA] text-[32px] font-extrabold leading-[40px] font-cabinet'>How to sell</h4>
          </div>
          <div className='flex flex-col md:flex-row md:space-x-[40px] space-y-[16px] md:space-y-[0px]'>
            <div className='w-full md:w-1/3'>
              <motion.img
                src={HomePhone}
                width={70}
                height={70}
                className="mb-[8px] md:mb-[10px]"
                initial={{ x: '-100%', opacity: 0 }}
                animate={controls1}
              />
              <motion.h4
                className='text-white font-bold text-[20px] md:text-[24px] leading-[30px] mb-[8px] font-cabinet'
                initial={{ x: '-100%', opacity: 0 }}
                animate={controls1}
              >
                Contact Us
              </motion.h4>
              <motion.p
                className='text-[#B7AAAA] text-[12px] md:text-[16px] font-medium font-satoshi'
                initial={{ x: '-100%', opacity: 0 }}
                animate={controls1}
              >
                Call us and tell us everything about the condition, then we go ahead to do research and confirm the information.
              </motion.p>
            </div>
            <div className='w-full md:w-1/3'>
              <motion.img
                src={GetOfferIcon}
                width={70}
                height={70}
                className="mb-[8px] md:mb-[10px]"
                initial={{ x: '-100%', opacity: 0 }}
                animate={controls2}
              />
              <motion.h4
                className='text-white font-bold text-[20px] md:text-[24px] leading-[30px] mb-[8px] font-cabinet'
                initial={{ x: '-100%', opacity: 0 }}
                animate={controls2}
              >
                Get Your Offer
              </motion.h4>
              <motion.p
                className='text-[#B7AAAA] text-[12px] md:text-[16px] font-medium font-satoshi'
                initial={{ x: '-100%', opacity: 0 }}
                animate={controls2}
              >
                We go ahead to offer you a fair price after your asking price with no form of obligation, no agent fees or commissions.
              </motion.p>
            </div>
            <div className='w-full md:w-1/3'>
              <motion.img
                src={GetPaidIcon}
                width={70}
                height={70}
                className="mb-[8px] md:mb-[10px]"
                initial={{ x: '-100%', opacity: 0 }}
                animate={controls3}
              />
              <motion.h4
                className='text-white font-bold text-[20px] md:text-[24px] leading-[30px] mb-[8px] font-cabinet'
                initial={{ x: '-100%', opacity: 0 }}
                animate={controls3}
              >
                Get Paid
              </motion.h4>
              <motion.p
                className='text-[#B7AAAA] text-[12px] md:text-[16px] font-medium font-satoshi'
                initial={{ x: '-100%', opacity: 0 }}
                animate={controls3}
              >
                We can pay you as fast as Now! In less than 14 short days you can have your payment!
              </motion.p>
            </div>
          </div>
        </div>
      </MaxWidthWrapper>
    </div>
  );
}
